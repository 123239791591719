/*
 *
 *  products sagas
 *
 */

import {
  call,
  put,
  all,
  delay,
  takeLatest,
  select,
} from "redux-saga/effects";
import lodash from "lodash";

import store2 from "store2";
import {
  LOAD_PRODUCT,
  LOAD_PRODUCTS,
  WISHLIST_PRODUCT,
  ADD_WISHLIST_PRODUCT,
  DELETE_WISHLIST_PRODUCT,
  REMOVE_WISHLIST_PRODUCT,
  SEARCH_PRODUCTS,
  CREATE_USER_COMMENT,
  PRODUCTS_DAY_FILTER,
  PRODUCT_FILTER,
  CREATE_PRODUCT_LIKE,
  CREATE_PRODUCT_UNLIKE,
  DELETE_USER_COMMENT,
  EDIT_USER_COMMENT,
  ADD_WISHLIST_RELATED_PRODUCT,
  DELETE_WISHLIST_RELATED_PRODUCT,
  LOAD_BANNER,
  ADD_WISHLIST_FILTER_PAGE,
  DELETE_WISHLIST_FILTER_PAGE,
  CLICK_TRACKING,
  SHARE_TRACKING,
  CREATE_RELATED_PRODUCT_LIKE,
  CREATE_RELATED_PRODUCT_UNLIKE,
} from "./constants";

import {
  loadProductSuccess,
  loadProductError,
  loadProducts as loadProductsAction,
  loadProduct as loadProductAction,
  loadProductsSuccess,
  loadProductsError,
  wishListProductSuccess,
  wishListProductError,
  addWishListProductSuccess,
  addWishListProductError,
  deleteWishListProductSuccess,
  deleteWishListProductError,
  removeWishListProductSuccess,
  removeWishListProductError,
  searchProducts as searchProductsAction,
  searchProductsError,
  searchProductsSuccess,
  createUserCommentSuccess,
  createUserCommentError,
  setHeadersData,
  productsDayFilterError,
  clearProductsRecords,
  productFilterSuccess,
  productFilterError,
  createProductLikeSuccess,
  createProductLikeError,
  createProductUnLikeSuccess,
  createProductUnLikeError,
  deleteUserCommentError,
  deleteUserCommentSuccess,
  editUserCommentSuccess,
  editUserCommentError,
  addWishlistProductLike,
  addWishlistProductUnLike,
  addWishListRelatedProductError,
  addWishListRelatedProductSuccess,
  deleteWishListRelatedProductSuccess,
  deleteWishListRelatedProductError,
  loadBanner as loadBannerAction,
  loadBannerSuccess,
  loadBannerError,
  addWishListFilterPageSuccess,
  addWishListFilterPageError,
  deleteWishListFilterPageSuccess,
  deleteWishListFilterPageError,
  clickTrackingSuccess,
  clickTrackingError,
  shareTrackingSuccess,
  shareTrackingError,
  createRelatedProductLikeSuccess,
  createRelatedProductLikeError,
  createRelatedProductUnLikeSuccess,
  createRelatedProductUnLikeError,
  clearSearchRecord as clearSearchRecordAction,
} from "./actions";

import {
  selectRecords,
  selectHeaders,
  selectProductFilter,
  selectWishListProducts,
  selectRecord,
  selectFilterRecords,
  selectFilterParams,
  selectHasMore,
  selectTotalItems,
} from "./selectors";

import {
  loadProduct,
  loadProducts,
  getAllWishlistProducts,
  addWishlistProduct,
  deleteWishlistProduct,
  searchProducts,
  createUserComment,
  createProductLike,
  createProductUnLike,
  deleteUserComment,
  editUserComment,
  loadBanner,
  clickTracking,
  shareTracking,
  postSecretKey,
} from "./remotes";

import {
  selectGeoLocation,
  selectLoggedIn,
  selectUser,
  selectIsMobileDevice,
  selectCategoriesOptions,
  selectCategories,
} from "store/session/selectors";

import { loadPostCategories } from "store/session/remotes";

import { postCategoriesSuccess } from "store/session/actions";

import { getDefaultHeaders, mapKeywordsId } from "utils/tools";

export function* loadProductsSaga() {
  yield takeLatest(
    LOAD_PRODUCTS,
    function* updater({ load, query, intialLoad }) {
      if (load) {
        try {
          const headers = yield select(selectHeaders());
          const geoLocation = yield select(selectGeoLocation());
          const loggedIn = yield select(selectLoggedIn());
          const productFilter = yield select(selectProductFilter());
          const storeRecords = yield select(selectRecords());

          const result = yield call(
            loadProducts,
            headers,
            geoLocation,
            loggedIn,
            productFilter,
            query,
          );

          const resultRecords = result?.response;
          let submitRecords;
          if (intialLoad) {
            submitRecords = lodash.unionBy({}, resultRecords, "id");
          } else {
            submitRecords = lodash.unionBy(storeRecords, resultRecords, "id");
          }
          if (result) {
            yield put(
              loadProductsSuccess(submitRecords, result?.hasMore || false, result?.total_items || false),
            );
          }
        } catch (error) {
          yield put(loadProductsError("Failed to load records"));
        }
      }
    },
  );
}

export function* loadProductSaga() {
  yield takeLatest(LOAD_PRODUCT, function* updater({ record, page }) {
    if (record) {
      try {
        const geoLocation = yield select(selectGeoLocation());
        const loggedIn = yield select(selectLoggedIn());
        const result = yield call(loadProduct, record, geoLocation, loggedIn);
        if (result) {
          // Delays the dispatch of loadProductSuccess untill the store is populated with an initial list of records.
          // if (page) {
          //   yield put(loadBannerAction(result, geoLocation, loggedIn));
          // } else {
          while (true) {
            const recordsInStore = yield select(selectRecords());
            if (recordsInStore && recordsInStore.length > 0) {
              break;
            }
            yield delay(500);
          }
          yield put(loadProductSuccess(result));
        } else {
          yield put(
            loadProductError(
              "This product is currently unavailable / Expired. Discover more favorite products on our platform.",
            ),
          );
        }
      } catch (error) {
        const Err =
          (error &&
            error.response &&
            error.response.data &&
            error.response.data.error) ||
          "This product is currently unavailable / Expired. Discover more favorite products on our platform.";
        yield put(loadProductError(Err));
      }
    }
  });
}

export function* wishListProductSaga() {
  yield takeLatest(WISHLIST_PRODUCT, function* updater({ load, intialLoad }) {
    if (load) {
      try {
        const user_info = yield select(selectUser());
        const headers = yield select(selectHeaders());
        const storeWishlist = yield select(selectWishListProducts());
        const result = yield call(
          getAllWishlistProducts,
          Object.assign({}, { id: user_info?.id }),
          headers,
        );
        if (result) {
          const resultRecords =
            (result?.response?.length > 0 &&
              result?.response?.map((e) =>
                Object.assign({}, { ...e }, { wishlist_status: true }),
              )) ||
            result?.response;
          let submitRecords;
          if (intialLoad) {
            submitRecords = lodash.unionBy({}, resultRecords, "id");
          } else {
            submitRecords = lodash.unionBy(storeWishlist, resultRecords, "id");
          }

          yield put(
            wishListProductSuccess(submitRecords, result?.hasMore || false, result?.total_items || false),
          );
        }
      } catch (error) {
        yield put(wishListProductError("Failed to load favorite deals"));
      }
    }
  });
}

export function* addWishListProductSaga() {
  yield takeLatest(ADD_WISHLIST_PRODUCT, function* updater({ record }) {
    if (record) {
      try {
        const user_info = yield select(selectUser());
        const recordsInStore = yield select(selectRecords());

        const result = yield call(
          addWishlistProduct,
          Object.assign(
            {},
            {
              vendor_id: record.vendor_id,
              posts_id: record.id,
              user_id: user_info?.id,
            },
          ),
        );

        const filterProduct =
          recordsInStore?.length > 0 &&
          recordsInStore.filter((r) => r.id === result.posts_id);
        const submitRecord = filterProduct?.length > 0 && filterProduct[0];
        if (result) {
          yield put(
            addWishListProductSuccess(
              Object.assign(
                {},
                { ...submitRecord },
                { wishlist_status: true, wishlist_id: result.id },
              ),
              "Favorite deal added successfully",
            ),
          );
        }
      } catch (error) {
        yield put(addWishListProductError("Failed to add favorite deal"));
      }
    }
  });
}

export function* deleteWishListProductSaga() {
  yield takeLatest(DELETE_WISHLIST_PRODUCT, function* updater({ record }) {
    if (record) {
      try {
        const recordsInStore = yield select(selectRecords());

        const filterProduct =
          recordsInStore?.length > 0 &&
          recordsInStore.filter((r) => r.id === record.id);
        const submitRecord = filterProduct?.length > 0 && filterProduct[0];
        const result = yield call(
          deleteWishlistProduct,
          Object.assign({}, { id: record.wishlist_id }),
        );
        if (result) {
          yield put(
            deleteWishListProductSuccess(
              Object.assign(
                {},
                { ...submitRecord },
                { wishlist_status: false },
              ),
              "Favorite deal removed successfully",
            ),
          );
        }
      } catch (error) {
        yield put(deleteWishListProductError("Failed to delete favorite deal"));
      }
    }
  });
}

export function* removeWishListProductSaga() {
  yield takeLatest(REMOVE_WISHLIST_PRODUCT, function* updater({ record }) {
    if (record) {
      try {

        // the below content helpful for remove add to favorite in home page
        const recordsInStore = yield select(selectRecords());

        const result = yield call(
          deleteWishlistProduct,
          Object.assign({}, { id: record.id }),
        );
        if (result) {

          const filterProduct = recordsInStore?.length > 0 && recordsInStore.filter((r) => r.wishlist_id === record.id);
          const submitRecord = filterProduct?.length > 0 && filterProduct[0];

          yield put(
            removeWishListProductSuccess(
              Object.assign({}, Object.assign({}, { id: record.id })),
              "Favorite deal removed successfully",
            ),
          );

          yield put(
            deleteWishListProductSuccess(
              Object.assign(
                {},
                { ...submitRecord },
                { wishlist_status: false },
              ),
              "Favorite deal removed successfully",
            ),
          );
        }
      } catch (error) {
        yield put(removeWishListProductError("Failed to delete favorite deal"));
      }
    }
  });
}

export function* createUserCommentSaga() {
  yield takeLatest(
    CREATE_USER_COMMENT,
    function* updater({ record, setNewComment, setLoader }) {
      if (record) {
        try {
          const recordsInStore = yield select(selectRecords());
          const result = yield call(createUserComment, record);
          if (result) {
            const filterProduct =
              recordsInStore?.length > 0 &&
              recordsInStore.filter((r) => r.id === result.posts_id);
            const submitRecord = (filterProduct?.length > 0 && filterProduct[0]) || (yield select(selectRecord()));
            const commentRes = Object.assign(
              {},
              { ...result },
              {
                user_name: record?.user_name,
                profile_image: record?.profile_image,
              },
            );

            let postComment =
              (submitRecord &&
                Object.keys(submitRecord).length > 0 &&
                submitRecord?.posts_comments) ||
              false;
            postComment = (postComment &&
              postComment.length > 0 &&
              postComment.find((el, index) => el.id !== result.id) && [
                commentRes,
                ...postComment,
              ]) || [commentRes];
            yield put(
              createUserCommentSuccess(
                Object.assign(
                  {},
                  { ...submitRecord },
                  { posts_comments: postComment },
                ),
                "comment added successfully",
              ),
            );
          }
        } catch (error) {
          yield put(createUserCommentError("Failed to Add User comment"));
        } finally {
          if (setNewComment) {
            yield call(setNewComment, "");
          }
          if (setLoader) {
            yield call(setLoader, false);
          }
        }
      }
    },
  );
}

export function* productsDayFilterSaga() {
  yield takeLatest(
    PRODUCTS_DAY_FILTER,
    function* updater({ record, pageName }) {
      if (record) {
        try {
          const headers = getDefaultHeaders();
          yield put(
            setHeadersData(
              Object.assign({}, { ...headers }, { filter: record?.day }),
            ),
          );
          if (pageName === "filter") {
            yield put(searchProductsAction(true));
          } else {
            yield put(loadProductsAction(true, false, true));
            yield put(clearProductsRecords());
          }
        } catch (error) {
          yield put(productsDayFilterError("Failed to load records"));
        }
      }
    },
  );
}

export function* productFilterSaga() {
  yield takeLatest(PRODUCT_FILTER, function* updater({ productFilter }) {
    if (productFilter) {
      try {
        const headers = getDefaultHeaders();
        const storeHeaders = yield select(selectHeaders());
        yield put(
          setHeadersData(
            Object.assign(
              {},
              { ...headers },
              { filter: storeHeaders?.filter || false },
            ),
          ),
        );
        yield put(productFilterSuccess(productFilter));
        yield put(clearProductsRecords());
        yield put(loadProductsAction(true, false, true));
      } catch (error) {
        yield put(productFilterError("Failed to load records"));
      }
    }
  });
}

export function* createProductLikeSaga() {
  yield takeLatest(
    CREATE_PRODUCT_LIKE,
    function* updater({ record, pageName }) {
      if (record) {
        try {
          const user_info = yield select(selectUser());
          let result = yield call(
            createProductLike,
            Object.assign(
              {},
              {
                vendor_id: record.vendor_id,
                posts_id: record.posts_id,
                user_id: user_info.id,
              },
            ),
          );

          if (result) {
            const submitRecord = Object.assign(
              {},
              { ...record },
              {
                likes_count: result.total_likes,
                user_like_status: result.action,
              },
            );
            if (pageName === "wishlist") {
              yield put(addWishlistProductLike(submitRecord, "Like"));
            } else if (pageName === "filter") {
              const postCategoriesOptions = yield select(
                selectCategoriesOptions(),
              );
              const categories = yield select(selectCategories());
              const filterRecords = yield select(selectFilterRecords());
              const hasMore = yield select(selectHasMore());
              const totalItems = yield select(selectTotalItems());

              const records =
                filterRecords &&
                filterRecords.map((el) => {
                  if (el.id === submitRecord.id) {
                    return submitRecord;
                  } else {
                    return el;
                  }
                });
              if (records?.length > 0 && postCategoriesOptions) {
                yield put(
                  postCategoriesSuccess(postCategoriesOptions, categories),
                );

                yield put(
                  searchProductsSuccess(
                    records,
                    hasMore || false,
                    totalItems || false,
                    "Like",
                  ),
                );
              }
            } else {
              yield put(createProductLikeSuccess(submitRecord, "Like"));
            }
          }
        } catch (error) {
          yield put(createProductLikeError("Failed to create a product like"));
        }
      }
    },
  );
}

export function* createProductUnLikeSaga() {
  yield takeLatest(
    CREATE_PRODUCT_UNLIKE,
    function* updater({ record, pageName }) {
      if (record) {
        try {
          const user_info = yield select(selectUser());
          let result = yield call(
            createProductUnLike,
            Object.assign(
              {},
              {
                vendor_id: record.vendor_id,
                posts_id: record.posts_id,
                user_id: user_info.id,
              },
            ),
          );

          if (result) {
            const submitRecord = Object.assign(
              {},
              { ...record },
              {
                likes_count: result.total_likes,
                user_like_status: result.action,
              },
            );
            if (pageName === "wishlist") {
              yield put(addWishlistProductUnLike(submitRecord, "Dislike"));
            } else if (pageName === "filter") {
              const postCategoriesOptions = yield select(
                selectCategoriesOptions(),
              );
              const categories = yield select(selectCategories());
              const filterRecords = yield select(selectFilterRecords());
              const hasMore = yield select(selectHasMore());
              const totalItems = yield select(selectTotalItems());

              const records =
                filterRecords &&
                filterRecords.map((el) => {
                  if (el.id === submitRecord.id) {
                    return submitRecord;
                  } else {
                    return el;
                  }
                });
              if (records?.length > 0 && postCategoriesOptions) {
                yield put(
                  searchProductsSuccess(
                    records,
                    hasMore || false,
                    totalItems || false,
                    "Dislike",
                  ),
                );
              }
            } else {
              yield put(createProductUnLikeSuccess(submitRecord, "Dislike"));
            }
          }
        } catch (error) {
          yield put(createProductUnLikeError("Failed to unlike a product"));
        }
      }
    },
  );
}

export function* deleteUserCommentSaga() {
  yield takeLatest(
    DELETE_USER_COMMENT,
    function* updater({ record, setLoader }) {
      if (record) {
        try {
          const result = yield call(deleteUserComment, record);
          if (result) {
            const recordsInStore = yield select(selectRecords());
            const filterProduct =
              recordsInStore?.length > 0 &&
              recordsInStore.filter((r) => r.id === record.posts_id);
            const submitRecord = (filterProduct?.length > 0 && filterProduct[0]) || (yield select(selectRecord()));

            let postComment =
              (submitRecord &&
                Object.keys(submitRecord).length > 0 &&
                submitRecord?.posts_comments) ||
              false;

            postComment =
              (postComment &&
                postComment.length > 0 &&
                postComment.filter((el) => el.id !== record.id)) ||
              postComment;

            yield put(
              deleteUserCommentSuccess(
                Object.assign(
                  {},
                  { ...submitRecord },
                  { posts_comments: postComment },
                ),
                "Comment Deleted Successfully",
              ),
            );
          } else {
            yield put(deleteUserCommentError("Something went wrong!"));
          }
        } catch (error) {
          yield put(deleteUserCommentError(error));
        } finally {
          if (setLoader) {
            yield call(setLoader, false);
          }
        }
      }
    },
  );
}

export function* editUserCommentSaga() {
  yield takeLatest(
    EDIT_USER_COMMENT,
    function* updater({ record, setEditedCmt, setLoader, setEditComment }) {
      if (record) {
        try {
          const result = yield call(editUserComment, record);
          if (result) {
            const recordsInStore = yield select(selectRecords());
            const filterProduct =
              recordsInStore?.length > 0 &&
              recordsInStore.filter((r) => r.id === record.posts_id);
            const submitRecord = filterProduct?.length > 0 && filterProduct[0];

            let postComment =
              (submitRecord &&
                Object.keys(submitRecord).length > 0 &&
                submitRecord?.posts_comments) ||
              false;

            postComment =
              (postComment &&
                postComment.length > 0 &&
                postComment.map((el) =>
                  el.id === record.id
                    ? Object.assign(
                        {},
                        { ...result },
                        {
                          user_name: record.user_name,
                          profile_image: record?.profile_image,
                        },
                      )
                    : el,
                )) ||
              false;

            yield put(
              editUserCommentSuccess(
                Object.assign(
                  {},
                  { ...submitRecord },
                  { posts_comments: postComment },
                ),
                "Comment Updated Successfully",
              ),
            );
          } else {
            yield put(editUserCommentError("Something went wrong!"));
          }
        } catch (error) {
          yield put(editUserCommentError("Something went wrong!"));
        } finally {
          if (setEditedCmt || setLoader || setEditComment) {
            yield call(setEditedCmt, "");
            yield call(setLoader, false);
            yield call(setEditComment, false);
          }
        }
      }
    },
  );
}

export function* addWishListProductRelatedSaga() {
  yield takeLatest(ADD_WISHLIST_RELATED_PRODUCT, function* updater({ record }) {
    if (record) {
      try {
        const user_info = yield select(selectUser());
        const recordInStore = yield select(selectRecord());

        const result = yield call(
          addWishlistProduct,
          Object.assign(
            {},
            {
              vendor_id: record.vendor_id,
              posts_id: record.id,
              user_id: user_info?.id,
            },
          ),
        );

        const filterProduct =
          (recordInStore &&
            Object.keys(recordInStore).length > 0 &&
            recordInStore?.related_posts) ||
          [];

        const relatedRecords =
          filterProduct?.length > 0 &&
          filterProduct.map((el) =>
            el.id === record.id
              ? Object.assign(
                  {},
                  { ...el },
                  { wishlist_status: true, wishlist_id: result.id },
                )
              : el,
          );

        const submitRecord =
          (relatedRecords?.length > 0 &&
            Object.assign({}, recordInStore, {
              related_posts: relatedRecords,
            })) ||
          recordInStore;
        yield delay(500);

        if (result && submitRecord) {
          yield put(
            addWishListRelatedProductSuccess(
              submitRecord,
              "Favorite deal added successfully",
            ),
          );
        }
      } catch (error) {
        yield put(
          addWishListRelatedProductError("Failed to add favorite deal"),
        );
      }
    }
  });
}

export function* deleteWishListRelatedProductSaga() {
  yield takeLatest(
    DELETE_WISHLIST_RELATED_PRODUCT,
    function* updater({ record }) {
      if (record) {
        try {
          const recordInStore = yield select(selectRecord());

          const filterProduct =
            (recordInStore &&
              Object.keys(recordInStore).length > 0 &&
              recordInStore?.related_posts) ||
            [];

          const result = yield call(
            deleteWishlistProduct,
            Object.assign({}, { id: record.wishlist_id }),
          );
          if (result) {
            const relatedRecords =
              filterProduct?.length > 0 &&
              filterProduct.map((el) =>
                el.id === record.id
                  ? Object.assign({}, { ...el }, { wishlist_status: false })
                  : el,
              );

            const submitRecord =
              (relatedRecords?.length > 0 &&
                Object.assign({}, recordInStore, {
                  related_posts: relatedRecords,
                })) ||
              recordInStore;
            yield delay(500);

            yield put(
              deleteWishListRelatedProductSuccess(
                submitRecord,
                "Favorite deal removed successfully",
              ),
            );
          }
        } catch (error) {
          yield put(
            deleteWishListRelatedProductError("Failed to delete favorite deal"),
          );
        }
      }
    },
  );
}

export function* loadBannerSaga() {
  yield takeLatest(LOAD_BANNER, function* updater({ record }) {
    if (record) {
      const geoLocation = yield select(selectGeoLocation());
      const loggedIn = yield select(selectLoggedIn());
      const isMobile = yield select(selectIsMobileDevice());
      try {
        const result = yield call(loadBanner, geoLocation, loggedIn);
        if (result?.response?.length) {
          let submitRecord = result?.response;
          if (isMobile) {
            submitRecord = submitRecord?.map((_) =>
              Object.assign({}, { url: _.banner_mobile_url, page: _.page }),
            );
          } else {
            submitRecord = submitRecord?.map((_) =>
              Object.assign({}, { url: _.banner_desktop_url, page: _.page }),
            );
          }

          yield put(loadBannerSuccess(submitRecord));
        }
      } catch (error) {
        const Err =
          (error &&
            error.response &&
            error.response.data &&
            error.response.data.error) ||
          "This product is currently unavailable / Expired. Discover more favorite products on our platform.";
        yield put(loadBannerError(Err));
      }
    }
  });
}

export function* searchProductsSaga() {
  yield takeLatest(SEARCH_PRODUCTS, function* updater({ query, element }) {
    if (query) {
      try {
        const geoLocation = yield select(selectGeoLocation());
        const loggedIn = yield select(selectLoggedIn());
        const headers = yield select(selectHeaders());
        const queryParams = yield select(selectFilterParams());
        const queryString =
          queryParams.online_store &&
          queryParams.walkin_store &&
          queryParams.online_store.toString() === "true" &&
          queryParams.walkin_store.toString() === "true"
            ? Object.assign({}, queryParams, {
                online_store: false,
                walkin_store: false,
              })
            : queryParams || false;
        const filterRecords = yield select(selectFilterRecords());
        if (!element) {
          yield put(clearSearchRecordAction());
        }

        const postCategories = yield call(loadPostCategories, loggedIn);

        const categories =
          postCategories &&
          postCategories.response &&
          postCategories.response.length > 0
            ? postCategories.response
            : [];
        const postCategoriesOptions = lodash
          .orderBy(
            categories.map(c => Object.assign({}, c, { order: parseInt(c.order)})).filter((_) => !_.parent),
            "order",
          )
          .map((c) => {
            return Object.assign({}, c, {
              value: c.id,
              title: c.category_name,
              disabled:
                categories.filter((_) => _.parent === c.id).length > 0
                  ? true
                  : false,
              children: lodash
                .orderBy(
                  categories.filter((_) => _.parent === c.id),
                  "order",
                )
                .map((c2) =>
                  Object.assign({}, c2, {
                    value: c2.id,
                    title: c2.category_name,
                    disabled:
                      categories.filter((_) => _.parent === c2.id).length > 0
                        ? true
                        : false,
                    children: lodash
                      .orderBy(
                        categories.filter((_) => _.parent === c2.id),
                        "order",
                      )
                      .map((c3) =>
                        Object.assign({}, c3, {
                          value: c3.id,
                          title: c3.category_name,
                          children: lodash
                            .orderBy(
                              categories.filter((_) => _.parent === c3.id),
                              "order",
                            )
                            .map((c4) =>
                              Object.assign({}, c4, {
                                value: c4.id,
                                title: c4.category_name,
                                children: lodash.orderBy(
                                  categories.filter((_) => _.parent === c4.id),
                                  "order",
                                ),
                              }),
                            ),
                        }),
                      ),
                  }),
                ),
            });
          });

        const categorieId = queryString?.category || false;
        if (postCategories) {
          let finalRecord;
          const ids = mapKeywordsId(categorieId, categories);
          if (categorieId) {
            const keyResponse = yield call(
              postSecretKey,
              Object.assign({}, { ids: ids }),
            );
            if (keyResponse?.key) {
              finalRecord = Object.assign({}, queryString, {
                key: keyResponse?.key,
              });
            } else {
              finalRecord = Object.assign({}, queryString);
            }
          } else {
            finalRecord = Object.assign({}, queryString);
          }

          const result = yield call(
            searchProducts,
            finalRecord,
            loggedIn,
            geoLocation,
            headers,
          );
          const resultRecords = result?.response;
          let submitRecords;
          if (element && filterRecords) {
            submitRecords = lodash.unionBy(filterRecords, resultRecords, "id");
          } else {
            submitRecords = resultRecords;
          }

          if (result) {
            yield put(postCategoriesSuccess(postCategoriesOptions, categories));
            yield put(
              searchProductsSuccess(
                submitRecords,
                result?.hasMore || false,
                result?.total_items || false,
              ),
            );
          }
        }
      } catch (error) {
        yield put(searchProductsError("Failed to delete favorite deal"));
      }
    }
  });
}

export function* addWishListFilterPageSaga() {
  yield takeLatest(ADD_WISHLIST_FILTER_PAGE, function* updater({ record }) {
    if (record) {
      try {
        const user_info = yield select(selectUser());
        const records = yield select(selectFilterRecords());

        const result = yield call(
          addWishlistProduct,
          Object.assign(
            {},
            {
              vendor_id: record.vendor_id,
              posts_id: record.id,
              user_id: user_info?.id,
            },
          ),
        );
        if (result) {
          const filterRecords =
            records?.length > 0 &&
            records.map((r) => {
              if (r.id === record.id) {
                return Object.assign({}, r, {
                  wishlist_status: true,
                  wishlist_id: result.id,
                });
              } else {
                return r;
              }
            });
          yield put(
            addWishListFilterPageSuccess(
              filterRecords,
              "Favorite deal added successfully",
            ),
          );
        } else {
          yield put(addWishListFilterPageError("Failed to add favorite deal"));
        }
      } catch (error) {
        yield put(addWishListFilterPageError("Failed to add favorite deal"));
      }
    }
  });
}

export function* deleteWishListFilterPageSaga() {
  yield takeLatest(DELETE_WISHLIST_FILTER_PAGE, function* updater({ record }) {
    if (record) {
      try {
        const records = yield select(selectFilterRecords());
        const result = yield call(
          deleteWishlistProduct,
          Object.assign({}, { id: record.wishlist_id }),
        );
        if (result) {
          const filterRecords =
            records?.length > 0 &&
            records.map((r) => {
              if (r.id === record.id) {
                return Object.assign({}, r, { wishlist_status: false });
              } else {
                return r;
              }
            });
          yield put(
            deleteWishListFilterPageSuccess(
              filterRecords,
              "Favorite deal removed successfully",
            ),
          );
        } else {
          yield put(
            deleteWishListFilterPageError("Failed to delete favorite deal"),
          );
        }
      } catch (error) {
        yield put(
          deleteWishListFilterPageError("Failed to delete favorite deal"),
        );
      }
    }
  });
}

export function* clickTrackingSaga() {
  yield takeLatest(CLICK_TRACKING, function* updater({ record }) {
    if (record) {
      try {
        const user_info = yield select(selectUser());
        const result = yield call(
          clickTracking,
          Object.assign({}, record, { user_id: user_info?.id }),
        );
        if (result) {
          yield put(clickTrackingSuccess("Tracking product successfully"));
        }
      } catch (error) {
        yield put(clickTrackingError("Failed to track the product"));
      }
    }
  });
}

export function* shareTrackingSaga() {
  yield takeLatest(SHARE_TRACKING, function* updater({ record }) {
    if (record) {
      try {
        const user_info = yield select(selectUser());
        const result = yield call(
          shareTracking,
          Object.assign({}, record, { user_id: user_info?.id }),
        );
        if (result) {
          yield put(shareTrackingSuccess("Tracking product successfully"));
        }
      } catch (error) {
        yield put(shareTrackingError("Failed to track the product"));
      }
    }
  });
}

export function* createRelatedProductLikeSaga() {
  yield takeLatest(CREATE_RELATED_PRODUCT_LIKE, function* updater({ record }) {
    if (record) {
      try {
        const user_info = yield select(selectUser());
        const recordInStore = yield select(selectRecord());

        const result = yield call(
          createProductLike,
          Object.assign(
            {},
            {
              vendor_id: record.vendor_id,
              posts_id: record.posts_id,
              user_id: user_info.id,
            },
          ),
        );

        const filterProduct =
          (recordInStore &&
            Object.keys(recordInStore).length > 0 &&
            recordInStore?.related_posts) ||
          [];

        const relatedRecords =
          filterProduct?.length > 0 &&
          filterProduct.map((el) =>
            el.id === record.id
              ? Object.assign(
                  {},
                  { ...el },
                  {
                    likes_count: result.total_likes,
                    user_like_status: result.action,
                  },
                )
              : el,
          );

        const submitRecord =
          (relatedRecords?.length > 0 &&
            Object.assign({}, recordInStore, {
              related_posts: relatedRecords,
            })) ||
          recordInStore;
        yield delay(500);

        if (result && submitRecord) {
          yield put(createRelatedProductLikeSuccess(submitRecord, "Like"));
        }
      } catch (error) {
        yield put(
          createRelatedProductLikeError("Failed to create a product like"),
        );
      }
    }
  });
}

export function* createRelatedProductUnLikeSaga() {
  yield takeLatest(
    CREATE_RELATED_PRODUCT_UNLIKE,
    function* updater({ record }) {
      if (record) {
        try {
          const user_info = yield select(selectUser());
          const recordInStore = yield select(selectRecord());

          const result = yield call(
            createProductUnLike,
            Object.assign(
              {},
              {
                vendor_id: record.vendor_id,
                posts_id: record.posts_id,
                user_id: user_info.id,
              },
            ),
          );

          const filterProduct =
            (recordInStore &&
              Object.keys(recordInStore).length > 0 &&
              recordInStore?.related_posts) ||
            [];

          const relatedRecords =
            filterProduct?.length > 0 &&
            filterProduct.map((el) =>
              el.id === record.id
                ? Object.assign(
                    {},
                    { ...el },
                    {
                      likes_count: result.total_likes,
                      user_like_status: result.action,
                    },
                  )
                : el,
            );

          const submitRecord =
            (relatedRecords?.length > 0 &&
              Object.assign({}, recordInStore, {
                related_posts: relatedRecords,
              })) ||
            recordInStore;
          yield delay(500);

          if (result && submitRecord) {
            yield put(
              createRelatedProductUnLikeSuccess(submitRecord, "Dislike"),
            );
          }
        } catch (error) {
          yield put(
            createRelatedProductUnLikeError("Failed to create a product like"),
          );
        }
      }
    },
  );
}

export default function* rootSaga() {
  yield all([
    loadProductsSaga(),
    loadProductSaga(),
    wishListProductSaga(),
    addWishListProductSaga(),
    deleteWishListProductSaga(),
    removeWishListProductSaga(),
    searchProductsSaga(),
    createUserCommentSaga(),
    productsDayFilterSaga(),
    productFilterSaga(),
    createProductLikeSaga(),
    createProductUnLikeSaga(),
    deleteUserCommentSaga(),
    editUserCommentSaga(),
    addWishListProductRelatedSaga(),
    deleteWishListRelatedProductSaga(),
    loadBannerSaga(),
    addWishListFilterPageSaga(),
    deleteWishListFilterPageSaga(),
    clickTrackingSaga(),
    shareTrackingSaga(),
    createRelatedProductLikeSaga(),
    createRelatedProductUnLikeSaga(),
  ]);
}
