import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  inputWrapper: {
    // backgroundColor: "white",
    width: "100%",
    borderRadius: "5px",
    height: "2.25rem",
    boxShadow: "0 0 8px #ddd",
    display: "flex",
    alignItems: "center",
    // borderBottomColor: "black",
    border: "1px solid " + theme?.palette?.headerTextColor?.main,
    boxShadow: "0 5px 5px rgb(0 0 0 / 10%)",
    "& .MuiInputBase-input": {
      padding: "0 10px !important",
    },
    "& .MuiAutocomplete-inputRoot": {
      paddingRight: "5px !important",
    },
    "& .MuiAutocomplete-endAdornment": {
      display: "none",
    },
  },
  fieldColor: {
    backgroundColor: "transparent",
    border: "none",
    width: "100%",
  },
  searchIcon: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    gap: "3px",
  },
  closeIcon: {
    height: "20px !important",
  },
  textSize: {
    fontSize: "14px",
  },
  error: {
    fontSize: "14px",
    color: "red",
  },
  bodyScroll: {
    maxHeight: 250,
    overflowY: "auto",
    paddingLeft: "0px",
    margin: "8px 0 8px 0",
    overflowX: "hidden",
    "&::-webkit-scrollbar": {
      width: 10,
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
      borderRadius: "30px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#9E9FA5",
      borderRadius: "30px",
    },
  },
  searchGrid: {
    marginLeft: '10px',
    marginBottom: '10px',
    cursor: 'pointer'
  },
  productTitle: {
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: "700",
    maxHeight: "42px",
    width: "100%",
    height: "100%",
    marginLeft: '5px',
    overflow: "hidden",
    display: "-webkit-box",
    "-webkit-line-clamp": 2,
    "-webkit-box-orient": "vertical",
    color: 'black',
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px !important",
      maxHeight: "34px",
    },
  },
  category_name: {
    fontSize: '11px',
    color: '#00CB74',
    fontWeight: 'bold',
    lineHeight: 0,
    padding: 0,
    margin: 0,
    [theme.breakpoints.down("sm")]: {
      fontSize: '10px'
    }
  },
  keywordTitle: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    marginBottom: '8px',
    justifyContent: 'space-between',
    '& div': {
      display: 'flex',
      alignItems: 'center',
    }
  },
  search: {
    color: theme?.palette?.headerTextColor?.main
  }
}));

export default useStyles;
