import React, { useState } from "react";
import Styles from "./styles";

import { Button, Grid, Typography } from "@mui/material";
import cookiesLogo from "assets/images/cookies.svg";
import { useCookies } from "react-cookie";
import SVG from 'react-inlinesvg';
import CookiesModelForm from "components/CookiesModelForm";
import store2 from "store2";

function Cookies(props) {
  const classes = Styles();
  const [show, setShow] = useState(true);
  const [cookies, setCookie] = useCookies(["cookieConsent"]);
  const [showCookiesSettings, setShowCookiesSettings] = useState(false);
  const cookieExpire = 1735707600;

  const acceptCookies = () => {
    store2.set("cookieConsent", true);
    setShow(false);
  };

  const declineCookies = () => {
    store2.set("cookieConsentDecline", false);
    setShow(false);
  };

  const handleSubmit = (data, dispatch, { form }) => {
    const submitRecord = Object.assign({}, { ...data });
    let mapData = Object.keys(submitRecord);
    if (mapData?.length > 0) {
      mapData.map((e) => {
        setCookie(e, true, { path: "/", maxAge: cookieExpire });
      });
      store2.set("cookieConsent", true);
      setShow(false);
      setShowCookiesSettings(false);
    }
  };

  const acceptAllCookies = (data) => {
    const submitRecord = Object.assign({}, { ...data });
    let mapData = Object.keys(submitRecord);
    if (mapData?.length > 0) {
      mapData.map((e) => {
        setCookie(e, true, { path: "/", maxAge: cookieExpire });
      });
      store2.set("cookieConsent", true);
      setShow(false);
      setShowCookiesSettings(false);
    }
  };

  return (
    <>
      {show ? (
        <Grid
          container
          className={classes.root}
          justifyContent="center"
          flexDirection="row"
          sx={{ gap: 2 }}
        >
          <Grid
            item
            xs={12}
            style={{ display: "flex", justifyContent: "center" }}
          >
            {/* <img
              src={cookiesLogo}
              alt="cookies"
              className={classes.cookiesImg}
            /> */}
            <SVG
              src={cookiesLogo}
              className={classes.cookiesImg}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1" className={classes.contentTitle}>
              We value your privacy
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.contentGrid}>
            <Typography variant="body1" className={classes.content}>
              We use cookies to enhance your experience and the services
              available on this site. To learn more, visit the Personal Data and
              Cookies page. You can, at any time, have control over which
              cookies you wish to enable
            </Typography>
          </Grid>
          <Grid
            container
            sx={{ gap: 2 }}
            justifyContent="center"
            className={classes.buttons}
          >
            <Button
              variant="contained"
              color="buttonColor"
              className={classes.cookiesBtn}
              onClick={declineCookies}
            >
              Decline
            </Button>
            <Button
              variant="contained"
              color="buttonColor"
              className={classes.cookiesBtn}
              onClick={() => setShowCookiesSettings(true)}
            >
              Customize Settings
            </Button>
            <Button
              variant="contained"
              color="accentColor"
              className={classes.customBtn}
              onClick={acceptCookies}
            >
              Accept
            </Button>
          </Grid>
        </Grid>
      ) : null}
      <CookiesModelForm
        initialValues={Object.assign({}, { strictly: true })}
        form={"cookiesSettingsForm"}
        show={showCookiesSettings}
        onSubmit={handleSubmit}
        onClose={() => setShowCookiesSettings(false)}
        acceptAllCookies={acceptAllCookies}
      />
    </>
  );
}

export default Cookies;
