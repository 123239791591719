import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "none",
    boxShadow: theme.shadows[5],
    outline: "none",
    position: "relative",
    borderRadius: "5px",
  },
  container: {
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    [theme.breakpoints.down("md")]: {
      maxHeight: "80vh",
      overflowX: "hidden",
      overflowY: "auto",
    },
  },
  title: {
    fontSize: "22px",
  },
  header: {
    padding: "20px 20px 0 20px",
  },
  inputContainer: {
    width: "100%",
    height: "50px",
    borderRadius: "5px",
    border: "1px",
    boxShadow: "0px 8px 20px 0px #0000001A",
    border: "1px solid #B1B1B1",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& .MuiInputBase-input": {
      padding: "0 10px !important",
      [theme.breakpoints.down("md")]: {
        fontSize: "12px !important",
      },
      [theme.breakpoints.up("md")]: {
        fontSize: "12px !important",
      },
    },
    [theme.breakpoints.up("md")]: {
      height: "32px !important",
    },
    [theme.breakpoints.down("sm")]: {
      height: "37px !important",
    },
  },
  closeContainer: {
    position: "absolute",
    top: "-30px",
    right: "-3%",
    cursor: "pointer",
  },
  closeIcon: {
    cursor: "pointer",
    color: "#E0E0E0",
    width: "28px",
    height: "28px",
    "@media (max-width: 1330px)": {
      width: "20px",
      height: "20px",
    },
  },
  locationTitle: {
    fontFamily: `var(--font)`,
    fontSize: "20px",
    fontWeight: 500,
    lineHeight: "42px",
    letterSpacing: "-0.015em",
    textAlign: "left",
    color: "#414141",
    [theme.breakpoints.down("md")]: {
      fontSize: "14px",
      lineHeight: "25px",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "14px",
      lineHeight: "25px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "15px",
    },
  },
  footerContainer: {
    marginTop: "20px",
    marginBottom: "10px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      marginTop: "14px",
      marginBottom: "3px",
    },
    [theme.breakpoints.up("md")]: {
      marginTop: "14px",
      marginBottom: "3px",
    },
  },
  confirmBtn: {
    fontFamily: "var(--font)",
    fontSize: "12px",
    fontWeight: 800,
    lineHeight: "29px",
    letterSpacing: "0.1em",
    textAlign: "center",
    color: theme?.palette?.accentTextColor?.main,
    width: "168px",
    height: "40px",
    borderRadius: "5px",
    cursor: "pointer",
    [theme.breakpoints.down("md")]: {
      width: "145px",
      height: "27px",
      fontSize: "10px",
    },
    [theme.breakpoints.up("md")]: {
      width: "145px",
      height: "27px",
      fontSize: "10px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "120px",
      height: "31px",
      fontSize: "8px",
      padding: 0,
    },
  },
  cancelBtn: {
    fontFamily: "var(--font)",
    fontSize: "12px",
    fontWeight: 800,
    lineHeight: "29px",
    letterSpacing: "0.1em",
    textAlign: "center",
    color: "#000000",
    marginLeft: "15px",
    width: "135px",
    height: "40px",
    borderRadius: "5px",
    [theme.breakpoints.down("md")]: {
      width: "108px",
      height: "27px",
      fontSize: "10px",
    },
    [theme.breakpoints.up("md")]: {
      width: "108px",
      height: "27px",
      fontSize: "10px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100px",
      height: "31px",
      fontSize: "8px",
      padding: 0,
    },
  },
  searchIcon: {
    cursor: "pointer",
    [theme.breakpoints.down("md")]: {
      height: "20px",
    },
    [theme.breakpoints.up("md")]: {
      height: "20px",
    },
  },
}));

export default useStyles;
