/**
 *
 *
 * Desktop Component
 *
 *
 */
import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useTheme } from "@mui/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import {
  AppBar,
  Grid,
  Toolbar,
  Typography,
  Button,
  IconButton,
  Menu,
  ListItemIcon,
  ListItemText,
  Badge,
} from "@mui/material";
import Styles, { StyledListItem } from "./styles";
import headerLogo from "assets/images/logoWithText1.svg";
import locationIcon from "assets/CommonIcons/marker.svg";
import notification from "assets/profile/bell-notification-social-media.png";
import fav from "assets/sideNav/heart.svg";
import lock from "assets/sideNav/user-lock.svg";
import logout from "assets/sideNav/exit.svg";
import downArrow from "assets/images/down_arrow.svg";
import account from "assets/images/header/account.svg";
import SVG from "react-inlinesvg";
import { capitalizeFirstLetter, checkString } from "utils/tools";
import { useEffect } from "react";
import CustomSearchField from "components/CustomSearchField";
import personLogo from "assets/images/card/profile.png";
import api from "helpers/api";

function DesktopView(props) {
  const classes = Styles();
  const {
    loggedIn,
    handleModalFunc,
    handleLogout,
    user,
    setShowMap,
    geoLocation,
    handleSearchFunc,
    suggestionOptions,
    setValue,
    value,
    handleReloadPage,
    handleClear,
    categories
  } = props;

  const [logo, setLogo] = useState(null);

  useEffect(() => {
    async function getLogo() {
      try {
        let response = await api.get('/rest/accounts/get/logo');
        setLogo(response.data.value)
      }
      catch (err) {
        console.log(err);
      }
    }
    getLogo()
  }, [])

  const { notification_details } = user || {};
  const navigate = useNavigate();
  const location = useLocation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [width, setWidth] = useState(window.innerWidth);
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  const userName = user?.name && capitalizeFirstLetter(user?.name);
  const profileLogo = user?.profile_image || personLogo;
  const mdDown = 900;

  const updateWidthAndHeight = () => {
    setWidth(window.innerWidth);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleProfileMenu = (list) => {
    setAnchorEl(null);
    setTimeout(() => {
      if (list?.value === "logout") {
        handleLogout();
        navigate(`/`);
      } else {
        navigate(`/profile/${list.value}`);
      }

    }, 500)
  };

  const handleProfile = () => {
    navigate("/profile/viewProfile");
    setAnchorEl(null);
  };

  const profileOptions = [
    { img: fav, label: "Favorite deals", value: "myWishlist" },
    { img: lock, label: "Change password", value: "changePassword" },
    { img: logout, label: "Logout", value: "logout" },
  ];

  useEffect(() => {
    window.addEventListener("resize", updateWidthAndHeight);
    return () => window.removeEventListener("resize", updateWidthAndHeight);
  }, []);

  return (
    <AppBar
      id="header"
      className={classes.appBar}
      color="headerPrimary"
      position="fixed"
    >
      <Toolbar className={classes.headerSection}>
        <Grid item xs={width < mdDown ? 1 : 2}>
          <a
            href={
              process.env.NODE_ENV === "development"
                ? "/"
                : global.config.APP_URL
            }
            onClick={handleReloadPage}
          >
            <img
              src={logo}
              alt="header_logo"
              className={classes.brandLogo}
            />
          </a>
        </Grid>
        <Grid item xs={8} className={classes.locationContainer}>
          <Grid
            item
            className={classes.location}
            onClick={() => setShowMap(true)}
          >
            {/* <img
              className={classes.header_location}
              src={locationIcon}
              alt="location"
            /> */}
            <SVG
              className={classes.header_location}
              src={locationIcon}
            />
            <Grid
              item
              className={classes.headerLocationText}
              style={{ display: "flex", flexDirection: "column" }}
            >
              {!geoLocation?.locationName && !geoLocation?.kiloMeter ? (
                <>
                  <span
                    style={{
                      fontSize: "12px",
                      lineHeight: "14px",
                    }}
                    className={classes.hello}
                  >
                    Hello
                  </span>
                  <span className={classes.text}>Choose your location for nearby deals</span>
                </>
              ) : (
                <>
                  {geoLocation?.kiloMeter &&
                    parseInt(geoLocation?.kiloMeter) > 0 ? (
                    <span
                      style={{
                        fontWeight: "bold",
                        lineHeight: "14px",
                        fontSize: "14px",
                        lineHeight: "15px",
                      }}
                    >
                      Near me - {geoLocation?.kiloMeter}km
                    </span>
                  ) : null}
                  {(geoLocation && geoLocation?.locationName && (
                    <span
                      style={{
                        fontWeight: "bold",
                        lineHeight: "14px",
                        fontSize: "14px",
                        lineHeight: "15px",
                      }}
                    >
                      {checkString(geoLocation?.locationName, 15, true)}
                    </span>
                  )) ||
                    null}
                </>
              )}
            </Grid>
          </Grid>
          <Grid
            item
            className={classes.searchContainer}
            style={
              width <= 1100 ? { minWidth: "310px" } : { minWidth: "520px" }
            }
          >
            <CustomSearchField
              type="text"
              placeholder={"Explore deals: Search now"}
              className={classes.searchBar}
              handleChange={handleSearchFunc}
              fullWidth
              options={suggestionOptions}
              setValue={setValue}
              value={value}
              handleClear={handleClear}
              categories={categories}
            />
          </Grid>
        </Grid>

        {!loggedIn && (
          <Grid item xs={2} className={classes.accountContainer}>
            {!sm ? (
              <Grid item>
                <Button
                  color="accentColor"
                  variant="contained"
                  className={classes.button}
                  onClick={() => handleModalFunc("login")}
                >
                  Sign in
                </Button>
              </Grid>
            ) : (
              <Grid item style={{ marginRight: "10px" }}>
                <IconButton>
                  <img src={account} alt="account" />
                </IconButton>
              </Grid>
            )}
          </Grid>
        )}

        {loggedIn && (
          <Grid
            item
            xs={2}
            style={{
              display: "flex",
              alignItems: "center",
              marginLeft: "20px",
            }}
          >
            <Typography className={classes.userName}>
              {checkString(userName)}
            </Typography>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
              disableRipple
              style={{ height: "50px" }}
            >
              <img src={profileLogo} className={classes.profileImg} alt="profile logo" />
            </IconButton>
            {!sm && (
              <Grid
                style={{
                  display: "flex",
                  justifyContent: "center",
                  height: "50px",
                }}
              >
                <img
                  src={downArrow}
                  onClick={handleMenu}
                  style={{ cursor: "pointer", width: "10px" }}
                  alt="down arrow"
                />
              </Grid>
            )}
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              className={classes.profileMenu}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <div className={classes.profile} onClick={() => handleProfile()}>
                <div className={classes.profileInfo}>
                  <img
                    src={profileLogo}
                    style={{
                      height: "23px",
                      width: "23px",
                      borderRadius: "100%",
                      objectFit: "cover"
                    }}
                    alt="profile logo"
                  />
                  <Typography className={classes.personName}>
                    {checkString(userName)}
                  </Typography>
                </div>
                <div>
                  <Badge
                    badgeContent={
                      notification_details &&
                      Array.isArray(notification_details) &&
                      notification_details.length
                    }
                    color="error"
                    onClick={() => navigate("/profile/viewProfile")}
                  >
                    <img
                      src={notification}
                      style={{ position: "relative", left: "-5px", width: "22px", height: "22px" }}
                      onClick={() => navigate("/profile/viewProfile")}
                      alt="notification"
                    />
                  </Badge>
                </div>
              </div>
              <div className={classes.list}>
                {profileOptions?.map((list) => {
                  const selected =
                    location?.pathname.indexOf(`/profile/${list.value}`) > -1
                      ? true
                      : false;
                  return (
                    <StyledListItem
                      button
                      key={list.value}
                      selected={selected}
                      onClick={() => handleProfileMenu(list)}
                    >
                      <ListItemIcon sx={{ minWidth: "40px" }}>
                        <SVG
                          src={list.img}
                          style={{
                            width: "22px",
                            height: "22px",
                            // border: "1px solid black",
                          }}
                          color={`${(selected) ? localStorage.getItem("accentColor") : "#868686"}`}
                          // className={selected ? "selectedOption" : ""}
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary={list.label}
                        className={classes.listLabel}
                      />
                    </StyledListItem>
                  );
                })}
              </div>
            </Menu>
          </Grid>
        )}
      </Toolbar>
    </AppBar>
  );
}

export default DesktopView;
