import React from "react";
import Styles from "./styles";
import { useSelector } from "react-redux";

function SideNavRow({
  record,
  handleClose,
  handleTreeSearch,
  setSubContainerEntries,
  setSubContainer,
  parentRef,
  categorieTravel,
}) {
  const classes = Styles();
  const mobileDevice = useSelector(
    (state) => state && state?.session && state?.session?.isMobile,
  );

  const openRow = () => {
    setSubContainer(false);
    setTimeout(() => {
      setSubContainer(true);
    }, 2);
    setSubContainerEntries(record);
    parentRef.current = false;
    categorieTravel.current = 1;
  };

  return (
    <div
      className={`${classes.categorieItems} ${
        !mobileDevice ? classes.categorieItemHovers : classes.categorieItemHovers
      }`}
      onClick={(evt) => {
        if (!record?.children || !record?.children?.length) {
          handleTreeSearch(record, "category");
          setSubContainer(false);
          setSubContainerEntries(false);
          if ((!record?.children || !record?.children?.length) && handleClose) {
            handleClose();
          }
        } else {
          openRow();
        }
      }}
    >
      <div>{record.category_name}</div>
      {record.children && record.children?.length > 0 ? ">" : null}
    </div>
  );
}

export default SideNavRow;
