import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  categorieItemBox: {
    background: `${theme.palette.headerBgColor.main}!important`,
    [theme.breakpoints.up("sm")]: {
      "&::-webkit-scrollbar": {
        width: 5,
      },
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
        borderRadius: "30px",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#9E9FA5",
        borderRadius: "30px",
      },
    },
  },
  container: {
    background: "white",
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    overflowX: "hidden",
    color: "#000",
    background: theme.palette.headerBgColor.main,
    borderBottom: "1px solid rgb(207 216 255)",
    boxShadow: "0px 7px 4px rgb(0 0 0 / 2%)",
    borderBottom: "1px solid #e0e0e0",
    whiteSpace: "nowrap",
  },
  categorieAll: {
    display: "flex",
    alignItems: "center",
    textTransform: "uppercase",
    padding: "0px 15px",
    minHeight: "44px",
    letterSpacing: "0.2px",
    fontWeight: 700,
    borderRight: "1px solid rgb(221 222 224)",
    left: 0,
    zIndex: 99,
    position: "absolute",
    left: "0px",
    width: "95px",
    background: theme.palette.headerBgColor.main,
    overflow: "hidden",
    cursor: "pointer",
    minWidth: "94px",
    [theme.breakpoints.down("sm")]: {
      position: "sticky !important",
      justifyContent: "center",
      minWidth: "auto",
      width: "auto !important",
    },
  },
  categorieAllText: {
    color: theme.palette.headerTextColor.main,
    cursor: "pointer",
    // fontWeight: 700,
    fontSize: "14px",
    marginLeft: "2px",
    textTransform: "capitalize",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
    },
  },
  categorieItem: {
    display: "flex",
    background: theme.palette.headerBgColor.main,
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      position: "relative",
      overflowX: "scroll !important",
      scrollbarWidth: "none", // Hide the scrollbar for firefox
      "&::-webkit-scrollbar": {
        display: "none", // Hide the scrollbar for WebKit browsers (Chrome, Safari, Edge, etc.)
      },
      "&-ms-overflow-style": {
        display: "none", // Hide the scrollbar for IE
      },
    },
    "@media (max-width: 680px) and (orientation:landscape)": {
      position: "relative",
      overflowX: "scroll !important",
      scrollbarWidth: "none", // Hide the scrollbar for firefox
      "&::-webkit-scrollbar": {
        display: "none", // Hide the scrollbar for WebKit browsers (Chrome, Safari, Edge, etc.)
      },
      "&-ms-overflow-style": {
        display: "none", // Hide the scrollbar for IE
      },
    },
  },
  link: {
    display: "flex",
    alignItems: "center",
    // textTransform: "capitalize",
    padding: "0px 15px",
    minHeight: "44px",
    letterSpacing: "0.2px",
    // fontWeight: 700,
    color: theme?.palette?.headerTextColor?.main || 'black',
    fontSize: "14px",
    cursor: "pointer",
    "&:hover": {
      // background: "#d9d7d2",
      color: theme.palette.accentColor.main,
      // borderLeft: "1px solid rgb(221 222 224)",
      // borderRight: "1px solid rgb(221 222 224)",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
      paddingTop: "0px"
    },
  },
  categorieArrow: {
    height: "35px",
    width: "35px",
    height: "20px",
    width: "33px",
    marginRight: "2px",
    marginBottom: "3px",
    paddingLeft: "10px",
    cursor: "pointer",
    color: theme?.palette?.headerTextColor?.main,
    [theme.breakpoints.down("sm")]: {
      height: "20px",
      width: "20px",
      marginRight: "0px",
      marginTop: "0px",
      paddingLeft: 0,
    },
  },
  arrowItem: {
    display: "flex",
    alignItems: "center",
    zIndex: 99,
  },
  letfArrow: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    position: "absolute",
    border: 0,
    cursor: "pointer",
    background: "none",
    // [theme.breakpoints.down("sm")]: {
    //   left: "65px",
    // },
  },
  rightArrow: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    right: 0,
    position: "absolute",
    border: 0,
    cursor: "pointer",
    background: "none",
  },
  arrow: {
    color: theme?.palette?.headerTextColor?.main + " !important",
  },
  // categoryDiv: {
  //   padding: "0",
  //   height: "100%",
  //   overflowY: "auto",
  //   overflowX: "hidden",
  //   "&::-webkit-scrollbar": {
  //     // width: 8,
  //     display: "none"
  //   },
  //   "&::-webkit-scrollbar-track": {
  //     boxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
  //     borderRadius: "30px",
  //   },
  //   "&::-webkit-scrollbar-thumb": {
  //     backgroundColor: "#9E9FA5",
  //     borderRadius: "30px",
  //   },
  // },
  openBtn: {
    padding: "0.375rem 1rem",
    fontWeight: "600",
    lineHeight: "1",
    textAlign: "center",
    whiteSpace: "nowrap",
    verticalAlign: "baseline",
    border: "1px solid " + theme?.palette?.headerTextColor?.main,
    fontSize: "12px",
    borderRadius: "10rem",
    justifySelf: "end",
    alignSelf: "center",
    marginLeft: "0.5rem !important",
    color: theme?.palette?.accentTextColor?.main,
    backgroundColor: theme?.palette?.accentColor?.main,
    cursor: "pointer",
  },
  parentCategoryTitle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  backBtn: {
    padding: "0.75rem 0.5rem",
    color: theme?.palette?.headerTextColor?.main
    // background: "#F1F2F3",
  },
  parentHeader: {
    color: theme.palette.accentColor.main,
    display: "flex",
    fontSize: "1.25rem",
    width: "100%",
    paddingTop: "0.75rem",
    paddingBottom: "0.75rem",
    padding: "0.875rem 1rem",
    lineHeight: "1.2",
    fontSize: "1rem",
    borderBottom: "1px solid " + theme.palette.accentColor.main,
    cursor: "pointer",
  },
  categorieItems: {
    display: "flex",
    gap: "5px",
    padding: "0.875rem 1rem",
    justifyContent: "space-between",
    cursor: "pointer",
    borderBottom: "0.0625rem solid #E3E4E5",
    fontSize: "14px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "13px",
    },
  },
  categorieItemHovers: {
    color: theme?.palette?.headerTextColor?.main,
    borderBottom: "1px solid " + theme?.palette?.headerTextColor?.main,
    "&:hover": {
      borderBottom: "1px solid " + theme.palette.accentColor.main,
      color: theme.palette.accentColor.main,
    },
  },
}));

export default useStyles;
