/**
 *
 *
 * Footer Component
 *
 *
 */
import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  ListItemText,
  SvgIcon,

} from "@mui/material";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Link } from "react-router-dom";
import AnchorLink from '@mui/material/Link';
import Styles from "./styles";

import footerLogo from "assets/images/logoWithText1.svg";

import { ReactComponent as Facebook } from "assets/socialMedia/facebook-app-symbol.svg";
import { ReactComponent as Twitter } from "assets/socialMedia/twitterX.svg";
import { ReactComponent as Instagram } from "assets/socialMedia/instagram.svg";
import { ReactComponent as Youtube } from "assets/images/follow_us/youtube.svg";
import { ReactComponent as Linkedin } from "assets/socialMedia/linkedin-big-logo.svg";
import { ReactComponent as Snapchat } from "assets/socialMedia/snapchat.svg";
import { ReactComponent as Pinterest } from "assets/socialMedia/pinterest.svg";
import { ReactComponent as Tiktok } from "assets/socialMedia/tiktok (1).svg";

import { useNavigate, useLocation } from "react-router-dom";
import EmailForm from "components/EmailForm";
import Tooltip from '@mui/material/Tooltip';
import api from 'helpers/api';
import Alert from '@mui/material/Alert';

function Footer(props) {
  const classes = Styles();
  const navigationPage = [
    {
      label: "About us",
      page: "about",
    },
    {
      label: "FAQ",
      page: "fqa",
    },
    {
      label: "Disclaimer",
      page: "disclaimer",
    },
    {
      label: "Privacy and Cookies policy",
      page: "privacyCookiesPolicy",
    },
    {
      label: "Contact us",
      page: "contact",
    },
  ];
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.down("md"));
  // const lg = useMediaQuery(theme.breakpoints.up("md"));
  const navigate = useNavigate();
  const location = useLocation();
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [logo, setLogo] = useState();

  useEffect(() => {
    async function getLogo() {
      try {
        let response = await api.get('/rest/accounts/get/footer/logo');
        let logo = response.data.value || footerLogo;
        setLogo(logo);
      }
      catch (err) {
        console.log(err);
      }
    }
    getLogo()
  }, [])

  const handleRedirect = () => {
    if (location && location?.pathname === "/") {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }

    navigate("/");
  };

  const handleSubscribe = (data) => {
    setLoading(true);
    // api.post('/rest/newsletter-subscripe', data).then(res => (res.data && res.data.message) ? setSuccess(res.data.message) : null).catch(() => setError('oops! Please try again later.'));
    api.post('/rest/newsletter-subscripe', data).then(res => {
      if (res.data && res.data.message) {
        setSuccess(res.data.message);
        setTimeout(() => {
          setLoading(false)
        }, 2000)
      }
    }).catch(() => {
      setError('oops! Please try again later.')
    })
  };

  return (
    <Grid container className={classes.footer} id={props?.id}>
      <Grid
        container
        alignItems="flex-start"
        justifyContent="center"
        className={classes.footerSection1}
      >
        <Grid item xs={12} md={4}>
          <Grid style={{
            display: "flex", justifyContent: md ? "center" : "center"
          }}>
            <a
              href={
                process.env.NODE_ENV === "development"
                  ? "/"
                  : global.config.APP_URL
              }
              onClick={handleRedirect}
              className={classes.logoGrid}
              style={{ display: md ? "flex" : "", justifyContent: md ? "center" : "" }}
            >
              <img
                src={logo}
                className={classes.logo}
                style={{ cursor: "pointer", width: "auto !important", height: md ? "90px" : "100px", marginBottom: md ? "20px" : "0px" }}
                alt="footer logo"
              />
            </a>
          </Grid>
          <Grid
            item
            xs={12}
            md={12}
            className={`${classes.title1} ${classes.description}`}
            style={{ textAlign: "justify" }}
          >
            DealPopup connects you to the best deals and savings. Sign up today to access exclusive offers and enjoy smarter shopping.
          </Grid>
        </Grid>
        <Grid item xs={12} md={3} style={md ? { marginBottom: "38px" } : { marginTop: "3px", display: "flex", justifyContent: "center" }}>
          <Grid>
            <div className={classes.footerNavTitle}>Navigation</div>
            {navigationPage.map((link, i) => {
              return (
                <ListItemText key={i} className={classes.listPage}>
                  <Link key={i} to={`/${link.page}`} className={classes.link}>
                    {link.label}
                  </Link>
                </ListItemText>
              );
            })}
          </Grid>
        </Grid>

        <Grid item xs={12} md={5} style={md ? {} : { marginTop: "3px" }}>
          <div className={classes.footerNavTitle}>Newsletter</div>
          <h3 className={classes.title1}>
            Unlock daily savings: <br />
            Enter your email and subscribe
          </h3>
          <Grid item xs={12}>
            <EmailForm
              name="emailForm"
              form={`emailForm`}
              onSubmit={handleSubscribe}
              loading={loading}
            />
          </Grid>
          <Grid item xs={12} style={{ marginTop: '25px' }}>
            {error ? <Alert variant="filled" severity="error" onClose={() => setError(false)}>{error}</Alert> : null}
            {success ? <Alert variant="filled" severity="success" onClose={() => setSuccess(false)}>{success}</Alert> : null}
          </Grid>

          <Grid
            item
            className={classes.footerNavTitle}
            style={{ marginTop: "20px" }}
          >
            <div>Engage with us</div>
            <Grid container xs={12} className={classes.socialMedia}>
              <Tooltip title="Facebook" placement="top">
                <Grid
                  item
                  className={`${classes.followSection} ${classes.facebook}`}
                >
                  {/* <img src={facebook} alt="facebook" className={classes.followLogo} onClick={() => {
                    window.open('https://www.facebook.com/dealpopup.official', "_blank")
                  }} /> */}
                  <SvgIcon component={Facebook} viewBox="0 0 600 476.6" className={classes.followLogo} onClick={() => {
                    window.open('https://www.facebook.com/dealpopup.official', "_blank")
                  }} />
                </Grid>
              </Tooltip>
              <Tooltip title="X" placement="top">
                <Grid
                  item
                  className={`${classes.followSection} ${classes.twitter}`}
                >
                  {/* <img src={twitter} alt="twitter" className={classes.followLogo} onClick={() => {
                    window.open('https://x.com/dealpopup?t=VXHZtiw1A57vsavdEqhDEw&s=08', '_blank')
                  }} /> */}
                  <SvgIcon component={Twitter} viewBox="0 0 600 476.6" className={classes.followLogo} onClick={() => {
                    window.open('https://x.com/dealpopup?t=VXHZtiw1A57vsavdEqhDEw&s=08', '_blank')
                  }} />
                </Grid>
              </Tooltip>
              <Tooltip title="linkedIn" placement="top">
                <Grid
                  item
                  className={`${classes.followSection} ${classes.linkedin}`}
                >
                  {/* <img src={linkedin} alt="linkedIn" className={classes.followLogo} onClick={() => {
                    window.open('https://www.linkedin.com/in/dealpopup-off', '_blank')
                  }} /> */}
                  <SvgIcon component={Linkedin} viewBox="0 0 600 476.6" className={classes.followLogo} onClick={() => {
                    window.open('https://www.linkedin.com/in/dealpopup-off', '_blank')
                  }} />
                </Grid>
              </Tooltip>
              <Tooltip title="tiktok" placement="top">
                <Grid
                  item
                  className={`${classes.followSection} ${classes.twitter}`}
                >
                  {/* <img src={tiktok} alt="linkedIn" className={classes.followLogo} onClick={() => {
                    window.open('https://www.tiktok.com/@dealpopup.official', '_blank')
                  }} /> */}
                  <SvgIcon component={Tiktok} viewBox="0 0 600 476.6" className={classes.followLogo} onClick={() => {
                    window.open('https://www.tiktok.com/@dealpopup.official', '_blank')
                  }} />
                </Grid>
              </Tooltip>
              <Tooltip title="pinterest" placement="top">
                <Grid
                  item
                  className={`${classes.followSection} ${classes.pinterest}`}
                >
                  {/* <img src={pinterest} alt="linkedIn" className={classes.followLogo} onClick={() => {
                    window.open('https://pin.it/3yufCQ2X9', '_blank')
                  }} /> */}
                  <SvgIcon component={Pinterest} viewBox="0 0 600 476.6" className={classes.followLogo} onClick={() => {
                    window.open('https://pin.it/3yufCQ2X9', '_blank')
                  }} />
                </Grid>
              </Tooltip>
              <Tooltip title="snapchat" placement="top">
                <Grid
                  item
                  className={`${classes.followSection} ${classes.snapchat}`}
                >
                  {/* <img src={snapchat} alt="linkedIn" className={classes.followLogo} onClick={() => {
                    window.open('https://www.snapchat.com/add/dealpopup', '_blank')
                  }} /> */}
                  <SvgIcon component={Snapchat} viewBox="0 0 600 476.6" className={classes.followLogo} onClick={() => {
                    window.open('https://www.snapchat.com/add/dealpopup', '_blank')
                  }} />
                </Grid>
              </Tooltip>
              <Tooltip title="Instagram" placement="top">
                <Grid
                  item
                  className={`${classes.followSection} ${classes.instagram}`}
                >
                  {/* <img src={instagram} alt="instagram" className={classes.followLogo} onClick={() => {
                    window.open('https://www.instagram.com/dealpopup.official/?igsh=b2lhZGRydnllZzkx', "_blank")
                  }} /> */}
                  <SvgIcon component={Instagram} viewBox="0 0 600 476.6" className={classes.followLogo} onClick={() => {
                    window.open('https://www.instagram.com/dealpopup.official/?igsh=b2lhZGRydnllZzkx', "_blank")
                  }} />
                </Grid>
              </Tooltip>
              {/* <Tooltip title="Youtube" placement="top">
                <Grid
                  item
                  className={`${classes.followSection} ${classes.youtube}`}
                >
                  <img src={youtube} alt="youtube" className={classes.followLogo} />
                </Grid>
              </Tooltip> */}
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {/* Footer bar */}

      {/* copyright bar */}
      <Grid item xs={12} className={classes.footerSection2}>
        <Typography className={classes.footerText}>
          Dealpopup © {new Date().getFullYear()}.  all rights reserved.
        </Typography>
        <Typography className={classes.footerText}>
          Powered by <AnchorLink target="_blank" href="https://ahsansolutions.com" underline="hover" sx={{ color: "footerTextColor.main", '&:hover': { color: "#ff5050" } }}>Ahsan Solutions</AnchorLink>
        </Typography>
      </Grid>
    </Grid >
  );
}

export default Footer;
