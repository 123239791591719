import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => {
  return {
    root: {
      display: "flex",
    },
    appBar: {
      width: "100%",
      left: "0px",
      top: "0px",
      boxShadow: "0px 0px 0px rgba(0, 0, 0, 0.05)",
      borderBottom: "1px solid rgb(221 222 224)",
      background: theme.palette.headerBgColor.main,
      zIndex: "999",
      position: "fixed",
      overflow: "hidden",
    },
    headerSection: {
      display: "grid",
      alignItems: "center",
      padding: 0,
    },
    location: {
      display: "flex",
      justifyContent: "center",
      alignItems: "flex-end",
      cursor: "pointer",
      marginRight: "10px",
    },
    header_location: {
      marginRight: "6px",
      marginBottom: "3px",
      width: "15px",
      color: theme?.palette?.headerTextColor?.main
    },
    headerLocationText: {
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "13px",
      lineHeight: "16px",
      // textTransform: "capitalize",
      color: theme?.palette?.headerTextColor?.main,
    },
    searchBar: {
      "& .MuiOutlinedInput-root": {
        position: "relative",
        background: "#FFFFFF",
        borderRadius: "5px",
        border: "none",
        outline: "none",
        fontFamily: "Poppins",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "13px",
        lineHeight: "21px",
        textTransform: "capitalize",
        outline: "none",
        "& fieldset": {
          borderColor: "#FFFFFF",
        },
        "&:hover fieldset": {
          borderColor: "#FFFFFF",
        },
        "&.Mui-focused fieldset": {
          borderColor: "#FFFFFF",
        },
      },
      "& .MuiOutlinedInput-input": {
        padding: "6px 0px",
      },
    },
    userName: {
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "15px",
      lineHeight: "19px",
      textAlign: "center",
      color: theme?.palette?.headerTextColor?.main,
      textTransform: "capitalize",
      letterSpacing: "-0.015em",
    },
    profileImg: {
      width: "33px",
      height: "33px",
      borderRadius: "100%",
      padding: "4px",
      objectFit: "cover"
    },
    searchContainer: {
      display: "flex",
    },
    accountContainer: {
      display: "flex",
      alignItems: "center",
    },
    location: {
      display: "flex",
      alignItems: "center",
      // background: "white",
      height: "40px",
      width: "100%",
      marginLeft: "10px",
      marginRight: "10px",
      cursor: "pointer",
    },
    headerRows: {
      marginLeft: "10px",
      marginRight: "10px",
    },
    hello: {
      color: theme?.palette?.headerTextColor?.main
    },
  };
});

export default useStyles;
