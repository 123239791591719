import { createTheme, responsiveFontSizes } from "@mui/material/styles";
import { decryption } from "cipher/cipher";

const { default: api } = require("helpers/api")

let theme = createTheme({
  button: {
    textTransform: "none",
  },
  typography: {
    fontFamily: "Poppins",
  },
  palette: {
    background: {
      default: "#f8f9fa", // override the background-color
    },
    primary: {
      main: "#22577a",
    },
    secondary: {
      main: "#edf2ff",
    },
    headerPrimary: {
      main: "#00CB74",
    },
    buttonColor: {
      main: "#FFFFFF",
    },
    buttonColor1: {
      main: "#00CB74",
    },
    buttonColor2: {
      main: "#FFB027",
    },
    buttonColor3: {
      main: "#22577a",
    },
    cancelBtn: {
      main: "#D7D7D7",
    },
    headerBgColor1: {
      main: "#00CB74",
    },
    headerBgColor2: {
      main: "#252C3A",
    },
    cardBtn: {
      main: "#22577a",
    },
    headerBgColor3: {
      main: "#ffffff",
    },
    headerTextColor: {
      main: "#ff0000",
    },
    accentColor: {
      main: "#22577a"
    },
    headerBgColor: {
      main: "#edf2f4",
    },
    footer: {
      main: '#8c93a1'
    },
    footerCard: {
      main: '#2e4879'
    },
    accentLight: {
      main: "#d6f8d6"
    },
    footerTextColor: {
      main: "#FFFFFF",
    },
    accentTextColor: {
      main: "#ff0000"
    }
  },
});

let defaultTheme = responsiveFontSizes(theme);

const getColors = async function () {
  try {
    let response = await api.get('/rest/accounts/get/colors');
    let decryptData = await decryption(response.data);
    // let responseData = response.data;
    return decryptData;
  }
  catch (err) {
    console.log(err);
  }
};

const getTheme = function (colors) {

  let primaryColor = colors[0].value;
  let secondaryColor = colors[1].value;
  let accentColor = colors[2].value;
  let footerCard1 = colors[3].value;
  let footerCard2 = colors[4].value;
  let accentLight = colors[5].value;
  let footerTextColor = colors[6].value;
  let accentTextColor = colors[7].value;
  let headerTextColor = colors[8].value;

  localStorage.setItem("primaryColor", primaryColor);
  localStorage.setItem("secondaryColor", secondaryColor);
  localStorage.setItem("accentColor", accentColor);
  localStorage.setItem("footerCard1", footerCard1);
  localStorage.setItem("footerCard2", footerCard2);
  localStorage.setItem("footerTextColor", footerTextColor);
  localStorage.setItem("accentTextColor", accentTextColor);
  localStorage.setItem("headerTextColor", headerTextColor);


  let theme = createTheme({
    button: {
      textTransform: "none",
    },
    typography: {
      fontFamily: "Poppins",
    },
    palette: {
      background: {
        default: secondaryColor, // override the background-color
      },
      primary: {
        main: accentColor,
      },
      secondary: {
        main: "#edf2ff",
      },
      headerPrimary: {
        main: "#00CB74",
      },
      buttonColor: {
        main: "#FFFFFF",
      },
      buttonColor1: {
        main: "#00CB74",
      },
      buttonColor2: {
        main: "#FFB027",
      },
      buttonColor3: {
        main: accentColor,
      },
      cancelBtn: {
        main: "#D7D7D7",
      },
      headerBgColor1: {
        main: "#00CB74",
      },
      headerBgColor2: {
        main: "#252C3A",
      },
      cardBtn: {
        main: accentColor,
      },
      headerBgColor3: {
        main: "#ffffff",
      },
      headerTextColor: {
        main: headerTextColor,
      },
      accentColor: {
        main: accentColor
      },
      headerBgColor: {
        main: primaryColor,
      },
      footer: {
        main: footerCard1
      },
      footerCard: {
        main: footerCard2
      },
      accentLight: {
        main: accentLight
      },
      footerTextColor: {
        main: footerTextColor,
      },
      accentTextColor: {
        main: accentTextColor
      }
    },
  });

  let themed = responsiveFontSizes(theme);
  return themed;
}

export { getColors, defaultTheme, getTheme };